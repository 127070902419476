var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContent',[_c('template',{slot:"right"},[_c('span',[_vm._v("المقالات")])]),_c('template',{slot:"left"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.article-modal",modifiers:{"article-modal":true}}],staticClass:"text-light",attrs:{"variant":"info","size":"md"}},[_vm._v("اضافة")])],1),_c('template',{slot:"content"},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"tbody-transition-props":_vm.transProps,"tbody-tr-class":"text-muted","responsive":"","table-class":"table-font h-100"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_vm._v(_vm._s((_vm.page - 1) * 10 + data.index + 1))]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":_vm.ImageURL(item),"width":"150","height":"80"}})]}},{key:"cell(content)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"success","pill":""},on:{"click":function($event){return _vm.getByID(item.id, true)}}},[_vm._v("عرض النص")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2 rounded-pill green"},[_vm._v(" "+_vm._s(new Date(item.created_at).toISOString().slice(0, 10))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"lg","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots","animation":"cylon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.getByID(item.id, true)}}},[_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"file-earmark-medical","scale":"0.5"}}),_vm._v(" عرض")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.getByID(item.id, false)}}},[_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-square","scale":"0.5"}}),_vm._v(" تعديل")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(item.id)}}},[_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"archive","scale":"0.5"}}),_vm._v(" حذف")],1)],1)]}}])})],1),_c('template',{slot:"modals"},[_c('ArticlesModal',{attrs:{"readonly":_vm.readonly},on:{"close":function($event){_vm.readonly = false}}})],1),_c('template',{slot:"bottom"},[_c('b-pagination',{attrs:{"current-page":_vm.meta.current_page,"per-page":_vm.meta.per_page,"total-rows":_vm.meta.last_page * _vm.meta.per_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }